<template>
  <el-table v-loading="loading" :data="logs">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="Hành động" prop="activity_type" min-width="100" align="center"></el-table-column>
    <el-table-column label="Bài viết" prop="name" min-width="300">
      <template slot-scope="scope">
        <span class="text-limited">{{ getTranslation(scope.row.object).name }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Thời gian" prop="logged_at" min-width="100" align="center">
    </el-table-column>
  </el-table>
</template>

<script>
import { getTranslation } from '@/utils/filters'

export default {
  name: 'ListActivityLog',
  props: {
    logs: Array,
    loading: Boolean
  },
  data() {
    return {
      data: []
    }
  },
  methods: {
    getTranslation
  }
}
</script>

<style lang="scss" scoped>
a {
  color: #1174d4;
}
</style>
