<template>
  <el-table v-loading="loading" :data="likes">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="Bài viết" prop="name" min-width="300">
      <template slot-scope="scope">
        <span class="text-limited">{{ scope.row.object ? scope.row.object.name : '' }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Thiết bị" prop="device_id" min-width="200">
    </el-table-column>
    <el-table-column label="Thời gian" prop="created_at" min-width="100" align="center">
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'ListLike',
  props: {
    likes: Array,
    loading: Boolean
  },
  data() {
    return {
      data: []
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
a {
  color: #1174d4;
}
</style>
