import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getActivityLogs(params) {
  return request({
    url: ENDPOINT.ACTIVITY_LOGS,
    method: 'get',
    params
  })
}
