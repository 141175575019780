<template>
  <el-dialog
    top="80px"
    width="1000px"
    class="history-user-popup"
    :title="`Lịch sử người dùng: ${user.name}`"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <div class="table-title">
      <el-tabs v-model="activeTab" type="card" @tab-click="handleChangeTab">
        <el-tab-pane
          :label="'Bình luận'"
          :name="'comment'"
        ></el-tab-pane>
        <el-tab-pane
          :label="'Yêu thích'"
          :name="'like'"
        ></el-tab-pane>
        <el-tab-pane
          :label="'Hành động khác'"
          :name="'log'"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="table">
      <list-comment
        v-if="activeTab === 'comment'"
        :loading="loading"
        :comments="data"
      />
      <list-like
        v-if="activeTab === 'like'"
        :loading="loading"
        :likes="data"
      />
      <list-activity-log
        v-if="activeTab === 'log'"
        :loading="loading"
        :logs="data"
      />
    </div>
    <el-row type="flex" align="middle" justify="end" class="pagination">
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 20, 30]"
        :page-size.sync="limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleChangeSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Đóng</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import ListComment from '../ListComment.vue'
import ListLike from '../ListLike.vue'
import ListActivityLog from '../ListActivityLog.vue'

import { getComments } from '../../../../services/comment'
import { getLikes } from '../../../../services/like'
import { getActivityLogs } from '../../../../services/activity-log'

export default {
  name: 'HistoryUser',
  components: {
    ListComment,
    ListLike,
    ListActivityLog
  },
  props: {
    visible: Boolean,
    user: Object
  },
  data() {
    return {
      loading: false,
      callingAPI: false,
      activeTab: 'comment',
      page: 1,
      limit: 10,
      total: 0,
      data: []
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      return {
        creator_id: this.user ? this.user.id : '',
        locale: this.language,
        page: this.page,
        per_page: this.limit,
        object_type: 'place',
        order: '-created_at',
        embedded: 'object',
        logger_id: this.user ? this.user.id : ''
      }
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },

    async loadData() {
      this.loading = true
      let request = null
      if (this.activeTab === 'comment') {
        request = getComments(this.params)
      } else if (this.activeTab === 'like') {
        request = getLikes(this.params)
      } else if (this.activeTab === 'log') {
        request = getActivityLogs(this.params)
      }
      await request.then((response) => {
        this.data = response.result
        this.total = response.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleChangePage(page) {
      this.page = page
      this.loadData()
    },

    handleChangeTab() {
      this.page = 1
      this.loadData()
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
</style>
